// components/WhatWeDo.js
import React from 'react';
import aiImg from "../assets/img/icon/Artificial Intellingence Based Development 1.png";
import blImg from "../assets/img/icon/Blockchain Technology Development 1.png";
import secImg from "../assets/img/icon/Information Security 1.png";
import iotImg from "../assets/img/icon/Internet of Things 1.png";
import seImg from "../assets/img/icon/Software Engineering.png";
import intImg from "../assets/img/icon/System Integration.png";
import { Link } from 'react-router-dom';

const WhatWeDo = () => {
  return (
    <section className="special_cource padding_top" id="what_we_do">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5">
            <div className="section_tittle text-center">
              <p>OUR SPECIALITIES</p>
              <h2>WHAT WE DO</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 course_details_left">
              <div className="content_wrapper">
                <div className="content">
                  Masagi adalah perusahaan dengan solusi teknologi kelas dunia yang berfokus pada rekayasa perangkat lunak,
                  pengembangan teknologi blockchain, pengembangan berbasis kecerdasan buatan, keamanan informasi, internet of
                  thing (IoT), integrasi sistem dan pelatihan kerja teknologi informasi. Masagi memberikan berbagai solusi
                  inovatif berdasarkan teknologi yang terintegrasi berdasarkan pada produk perangkat lunak dengan kinerja
                  terbaik bagi dunia usaha dalam memecahkan masalah lebih efektif dan efisien.
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <ServiceCard
            imgSrc={aiImg}
            link={{
              pathname: "/service/ai",
              search: "",
              hash: "#content",
              state: { fromDashboard: true }
            }}
            title="AI based development"
            subtitle="Artificial intelligence"
          />
          <ServiceCard
            imgSrc={blImg}
            link={{
              pathname: "/service/blockchain",
              search: "",
              hash: "#content",
              state: { fromDashboard: true }
            }}
            title="Blockchain development"
            subtitle="Blockchain"
          />
          <ServiceCard
            imgSrc={secImg}
            link={{
              pathname: "/service/security",
              search: "",
              hash: "#content",
              state: { fromDashboard: true }
            }}
            title="Information security"
            subtitle="Information security"
          />
          <ServiceCard
            imgSrc={iotImg}
            link={{
              pathname: "/service/iot",
              search: "",
              hash: "#content",
              state: { fromDashboard: true }
            }}
            title="Internet of thing development"
            subtitle="Internet of thing"
          />
          <ServiceCard
            imgSrc={seImg}
            link={{
              pathname: "/service/software-engineering",
              search: "",
              hash: "#content",
              state: { fromDashboard: true }
            }}
            title="Software engineering development"
            subtitle="Software engineering"
          />
          <ServiceCard
            imgSrc={intImg}
            link={{
              pathname: "/service/integration",
              search: "",
              hash: "#content",
              state: { fromDashboard: true }
            }}
            title="System integration"
            subtitle="System integration"
          />
        </div>
      </div>
    </section>
  );
}

const ServiceCard = ({ imgSrc, link, title, subtitle }) => {
  return (
    <div className="col-sm-6 col-lg-4 mb-3">
      <div className="single_special_cource" style={{ border: '1px solid #edeff2' }}>
        <img src={imgSrc} className="special_img" alt="" style={{ width: '80%', margin: '42px auto' }} />
        <div className="special_cource_text">
          <Link to={link} className="btn_4">{subtitle}</Link>
          <Link to={link}>
            <h3>{title}</h3>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
