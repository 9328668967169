import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Contact from './pages/contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import Home from './pages/home';
import About from './pages/about';
import AIService from './pages/whatWeDo/ai';
import SecurityService from './pages/whatWeDo/security';
import BlockchainService from './pages/whatWeDo/blockchain';
import IoTService from './pages/whatWeDo/iot';
import SoftwareEngineeringService from './pages/whatWeDo/softwareEngineering';
import IntegrationService from './pages/whatWeDo/integration';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/service/ai" element={<AIService />} />
        <Route path="/service/security" element={<SecurityService />} />
        <Route path="/service/blockchain" element={<BlockchainService />} />
        <Route path="/service/iot" element={<IoTService />} />
        <Route path="/service/software-engineering" element={<SoftwareEngineeringService />} />
        <Route path="/service/integration" element={<IntegrationService />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App