import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import securepng from "../../assets/img/icon/Information Security 2.png"
import RootLayout from "../../components/layout";

const SecurityService = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <RootLayout>
            <section className="breadcrumb breadcrumb_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>What We Do</h2>
                                    <p>Home<span>/</span>What We Do</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="blog_area single-post-area section_padding" id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img className="img-fluid" src={securepng} alt="" />
                                </div>
                                <div className="blog_details">
                                    <h2>Security</h2>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="far fa-user"></i> Technology information</a></li>
                                    </ul>
                                    <p>
                                        Keamanan dalam teknologi informasi (IT security) adalah praktek melindungi sistem komputer, jaringan, 
                                        dan data dari ancaman, akses yang tidak sah, kerusakan, atau pencurian. Keamanan ini mencakup berbagai teknologi, 
                                        proses, dan praktik yang dirancang untuk menjaga kerahasiaan, integritas, dan ketersediaan informasi.
                                    </p>
                                    <p>
                                        - Strategi Keamanan Informasi : <br />
                                        <strong>Firewall</strong>: <br />
                                        Menggunakan perangkat lunak dan perangkat keras untuk memantau dan mengontrol lalu lintas jaringan serta mendeteksi dan menghapus malware.<br />
                                        <strong>Enkripsi</strong>: <br />
                                        Proses mengubah data menjadi format yang tidak dapat dibaca tanpa kunci dekripsi, melindungi data saat disimpan dan ditransmisikan. <br/>
                                        <strong>Manajemen Akses</strong>: <br />
                                        Mengontrol siapa yang memiliki akses ke sistem dan data tertentu, menggunakan otentikasi multi-faktor (MFA) dan kontrol akses berbasis peran (RBAC). <br/>
                                        <strong>Pemantauan Logging</strong>: <br />
                                        Melacak dan mencatat aktivitas sistem untuk mendeteksi dan merespons insiden keamanan dengan cepat. <br/>
                                    </p>
                                    <p>
                                        Dalam era digital ini, keamanan informasi sangat penting karena data merupakan aset berharga bagi individu dan organisasi. 
                                        Pelanggaran keamanan dapat menyebabkan kerugian finansial, kerusakan reputasi, dan hilangnya kepercayaan pelanggan. 
                                        Oleh karena itu, investasi dalam keamanan informasi adalah langkah penting untuk melindungi data dan memastikan kelangsungan operasional bisnis.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title">Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                <a href="#">project</a>
                                                </li>
                                                <li>
                                                <a href="#">programing</a>
                                                </li>
                                                <li>
                                                <a href="#">technology</a>
                                                </li>
                                                <li>
                                                <a href="#">security</a>
                                                </li>
                                                <li>
                                                <a href="#">sistem</a>
                                                </li>
                                                <li>
                                                <a href="#">website</a>
                                                </li>
                                                <li>
                                                <a href="#">application</a>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title">Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder='Enter email' required />
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default SecurityService;