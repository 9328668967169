import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import sepng from "../../assets/img/icon/Software Engineering.png";
import RootLayout from "../../components/layout";

const SoftwareEngineeringService = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <RootLayout>
            <section className="breadcrumb breadcrumb_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>What We Do</h2>
                                    <p>Home<span>/</span>What We Do</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="blog_area single-post-area section_padding" id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img className="img-fluid" src={sepng} alt=""/>
                                </div>
                                <div className="blog_details">
                                    <h2>Software Engineering</h2>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><i className="far fa-user"></i> Technology information</li>
                                    </ul>
                                    <p>
                                        Rekayasa perangkat lunak (software engineering) adalah disiplin ilmu yang berfokus pada desain, pengembangan, pengujian, dan pemeliharaan perangkat lunak. 
                                        Ini mencakup penerapan prinsip-prinsip teknik dan ilmu komputer untuk menciptakan perangkat lunak yang efektif, efisien, dapat diandalkan, dan dapat dipelihara.
                                    </p>
                                    <p>
                                        - Tahapan dalam Rekayasa Perangkat Lunak : <br />
                                        <strong>Analisis Kebutuhan (Requirement Analysis)</strong>: <br />
                                        Mengidentifikasi dan mendokumentasikan kebutuhan pengguna dan sistem. Ini termasuk spesifikasi fungsional dan non-fungsional yang harus dipenuhi oleh perangkat lunak.<br />
                                        <strong>Desain (Design)</strong>: <br />
                                        Merancang arsitektur dan detail teknis perangkat lunak. Desain ini mencakup perancangan database, antarmuka pengguna, dan struktur program.<br/>
                                        <strong>Pengembangan (Development)</strong>: <br />
                                        Menulis kode sumber untuk mengimplementasikan desain perangkat lunak. Tahap ini melibatkan penggunaan bahasa pemrograman dan alat pengembangan perangkat lunak.<br/>
                                        <strong>Pengujian (Testing)</strong>: <br />
                                        Memeriksa dan menguji perangkat lunak untuk memastikan bahwa itu berfungsi sesuai dengan spesifikasi dan bebas dari bug. 
                                        Pengujian dapat mencakup unit testing, integration testing, system testing, dan user acceptance testing (UAT).<br/>
                                        <strong>Implementasi (Implementation)</strong>: <br />
                                        Memasang perangkat lunak ke lingkungan produksi dan memastikan bahwa itu berjalan dengan benar di lingkungan nyata.<br/>
                                        <strong>Pemeliharaan (Maintenance)</strong>: <br />
                                        Melakukan pembaruan, perbaikan bug, dan penyesuaian untuk memastikan perangkat lunak tetap relevan dan berfungsi dengan baik seiring waktu.<br/>
                                    </p>
                                    <p>
                                        Rekayasa perangkat lunak adalah disiplin yang kompleks dan penting dalam dunia teknologi informasi. 
                                        Dengan mengikuti metodologi dan prinsip-prinsip yang tepat, serta memanfaatkan alat dan teknologi modern, pengembang perangkat lunak dapat menciptakan solusi yang andal, efisien, dan sesuai dengan kebutuhan pengguna. 
                                        Tantangan-tantangan dalam bidang ini juga terus berkembang, menuntut profesional di bidang ini untuk terus belajar dan beradaptasi dengan perubahan teknologi.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title">Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                <a href="#">project</a>
                                                </li>
                                                <li>
                                                <a href="#">programing</a>
                                                </li>
                                                <li>
                                                <a href="#">technology</a>
                                                </li>
                                                <li>
                                                <a href="#">security</a>
                                                </li>
                                                <li>
                                                <a href="#">sistem</a>
                                                </li>
                                                <li>
                                                <a href="#">website</a>
                                                </li>
                                                <li>
                                                <a href="#">application</a>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title">Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder='Enter email' required />
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default SoftwareEngineeringService;