import React from 'react';
import logo from '../assets/img/logo_masagi_crop.png';
import { FaAngleRight, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";


const Footer = () => (
  <footer className="footer-area">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-sm-6 col-md-4 col-xl-3">
          <div className="single-footer-widget footer_1">
            <a href="/"> 
              <img src={logo} alt="" style={{marginBottom: '5px'}} /> 
            </a>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-4">
          <div className="single-footer-widget footer_2">
            <h4>Newsletter</h4>
            <p>Stay updated with our latest trends Seed heaven so said place winged over given forth fruit.</p>
            <form action="#">
              <div className="form-group">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder='Enter email address' onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Enter email address'} />
                  <div className="input-group-append">
                    <button className="btn btn_1" type="button"><FaAngleRight /></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-md-4">
          <div className="single-footer-widget footer_2">
            <h4>Contact us</h4>
            <div className="contact_info">
              <p>Point Lab Co-working Space <br />Graha POS Lantai 2 Blok C Jalan Banda No.30 Citarum, Bandung Wetan, Kota Bandung Jawa Barat Indonesia</p>
              <p><span> Phone :</span> +62 81120002410</p>
              <p><span>Email :</span> helpdesk@masagi.co.id</p>
            </div>
            <div className="social_icon">
              <a href="https://www.instagram.com/masagi.ind?igsh=cndobjk5dHNzN3Rw" target='_blank'> <FaInstagram style={{background: 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'}}/> </a>
              <a href="https://www.linkedin.com/in/mitra-solusi-aktual-integrasi-975174265"  target='_blank' style={{color:'rgb(10, 102, 194)'}}> <FaLinkedin /> </a>
              <a href="https://wa.me/6281120002410"  target='_blank' style={{color:'#25D366'}}> <FaWhatsapp /> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="copyright_part_text text-center">
            <div className="row">
              <div className="col-lg-12">
                <p className="footer-text m-0">
                  Copyright &copy;{new Date().getFullYear()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
