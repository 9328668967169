import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import iotpng from "../../assets/img/icon/Internet of Things 2.png"
import RootLayout from "../../components/layout";

const IoTService = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <RootLayout>
            <section className="breadcrumb breadcrumb_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>What We Do</h2>
                                    <p>Home<span>/</span>What We Do</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="blog_area single-post-area section_padding" id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img className="img-fluid" src={iotpng} alt="" />
                                </div>
                                <div className="blog_details">
                                    <h2>Internet of Things</h2>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="far fa-user"></i> Technology information</a></li>
                                    </ul>
                                    <p>
                                        Internet of Things (IoT) adalah konsep di mana berbagai perangkat fisik dihubungkan ke internet, memungkinkan mereka untuk mengumpulkan, berbagi, dan bertukar data. 
                                        Perangkat-perangkat ini mencakup segala sesuatu mulai dari alat rumah tangga pintar, mobil, hingga mesin industri, dan bahkan perangkat medis.
                                    </p>
                                    <p>
                                        - Cara Kerja IoT : <br />
                                        IoT bekerja dengan menghubungkan perangkat yang dilengkapi dengan sensor, perangkat lunak, dan teknologi lainnya ke internet. 
                                        Berikut adalah komponen utama yang membuat IoT berfungsi: <br/>
                                        <strong>Sensor dan Aktuator</strong>: <br />
                                        Sensor mengumpulkan data dari lingkungan (misalnya, suhu, kelembaban, detak jantung), sementara aktuator melakukan tindakan berdasarkan 
                                        data tersebut (misalnya, menyalakan lampu, mengatur termostat).<br />
                                        <strong>Konektivitas</strong>: <br />
                                        Perangkat IoT menggunakan berbagai metode konektivitas seperti Wi-Fi, Bluetooth, RFID, dan jaringan seluler untuk terhubung ke internet dan satu sama lain. <br/>
                                        <strong>Data Processing</strong>: <br />
                                        Data yang dikumpulkan oleh sensor dikirim ke sistem cloud atau server lokal untuk diproses. 
                                        Proses ini dapat melibatkan analisis data untuk menghasilkan wawasan atau mengambil keputusan otomatis. <br/>
                                        <strong>Antarmuka Pengguna</strong>: <br />
                                        Pengguna dapat berinteraksi dengan perangkat IoT melalui aplikasi mobile atau web, yang memungkinkan mereka untuk memantau dan mengontrol perangkat dari jarak jauh. <br/>
                                    </p>
                                    <p>
                                        Internet of Things (IoT) adalah teknologi yang sangat potensial untuk merevolusi berbagai aspek kehidupan kita dengan menghubungkan perangkat fisik ke internet. 
                                        Meskipun menawarkan banyak manfaat seperti otomatisasi, efisiensi, dan pemantauan jarak jauh, IoT juga menghadapi tantangan yang perlu diatasi untuk mencapai potensi penuhnya. 
                                        Dengan terus berkembangnya teknologi dan peningkatan standar keamanan, IoT akan terus memainkan peran penting dalam membentuk masa depan digital kita.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title">Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                <a href="#">project</a>
                                                </li>
                                                <li>
                                                <a href="#">programing</a>
                                                </li>
                                                <li>
                                                <a href="#">technology</a>
                                                </li>
                                                <li>
                                                <a href="#">internet</a>
                                                </li>
                                                <li>
                                                <a href="#">sistem</a>
                                                </li>
                                                <li>
                                                <a href="#">IoT</a>
                                                </li>
                                                <li>
                                                <a href="#">application</a>
                                                </li>
                                                <li>
                                                <a href="#">sensor</a>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title">Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder='Enter email' required />
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default IoTService;