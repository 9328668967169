// components/About.js
import React from 'react';
import learning from '../assets/img/learning_img.png';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <section className="learning_part" id="about">
        <div className="container">
          <div className="row align-items-sm-center align-items-lg-stretch">
            <div className="col-md-7 col-lg-7">
              <div className="learning_img">
                <img src={learning} alt="" />
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="learning_member_text">
                <h5>OUR STORY</h5>
                <h2>MITRA SOLUSI AKTUAL INTEGRASI</h2>
                <p>
                  Masagi terbentuk berawal dari sebuah harapan yang dapat memudahkan para pelaku bisnis terintegrasi satu
                  sama lain dengan solusi teknologi informasi dan sistem informasi. Masagi hadir untuk menjadi perusahaan
                  konsultan teknologi informasi yang memiliki kompetensi nasional dan internasional, berorientasi pada
                  bisnis komersial dan pemerintahan, serta bertekad untuk menciptakan produk dan layanan yang mampu
                  bersaing dan mengambil bagian dalam perkembangan teknologi informasi lokal dan internasional...
                </p>
                <Link 
                  to={{
                    pathname: "/about/",
                    search: "",
                    hash: "#about",
                    state: { fromDashboard: true }
                  }} 
                  className="btn_1">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
