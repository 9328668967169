import React, { useEffect } from 'react';
import {APIProvider, AdvancedMarker, Map, Pin} from '@vis.gl/react-google-maps';
import RootLayout from '../../components/layout';
import { useLocation } from 'react-router-dom';
import GetInTouch from '../../components/getInTouch';

const Contact = () => {
    const pointPosition = {lat: -6.90650, lng: 107.61718};
    const grayStyles = [
        {
        featureType: "all",
        stylers: [
            { saturation: 0 },
            { lightness: 0 }
        ]
        },
        {elementType: 'labels.text.fill', stylers: [{color: '#ccdee9'}]}
    ];
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        }
    }, [hash]);

    return (
        <RootLayout>
            <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY}>
                <div>
                    <section className="breadcrumb breadcrumb_bg" id="maps">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>Contact us</h2>
                                    <p>Home<span>/</span>Contact us </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>

                    <section className="contact-section section_padding">
                        <div className="container">
                            <div className="d-none d-sm-block mb-5 pb-4">
                            <Map
                                mapId={process.env.REACT_APP_MAPS_ID} 
                                defaultZoom={17} 
                                defaultCenter={{lat: -6.90650, lng: 107.61718}}
                                style={{height: '480px'}}
                                styles={grayStyles}
                                scrollwheel={false}
                            >
                                <AdvancedMarker
                                    key='MASAGI'
                                    title='Mitra Solusi Aktual Integrasi (MASAGI)'
                                    position={pointPosition}
                                >
                                    <Pin glyphColor={'#000'} borderColor={'#000'} />
                                </AdvancedMarker>
                            </Map>
                            </div>
                            <div className="row">
                            <GetInTouch />
                            <div className="col-lg-4">
                                <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-home"></i></span>
                                <div className="media-body">
                                    <h3>Point Lab Co-working Space</h3>
                                    <p>Graha POS Lantai 2 Blok C Jalan Banda No.30 Citarum, Bandung Wetan, Kota Bandung Jawa Barat Indonesia</p>
                                </div>
                                </div>
                                <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                <div className="media-body">
                                    <h3>+62 81120002410</h3>
                                    <p>Mon to Fri 9am to 6pm</p>
                                </div>
                                </div>
                                <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-email"></i></span>
                                <div className="media-body">
                                    <h3>helpdesk@masagi.co.id</h3>
                                    <p>Send us your query anytime!</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                </div>
            </APIProvider>
        </RootLayout>
    )
};

export default Contact;
