import React, { useEffect, useState } from 'react';
import logo from '../assets/img/logo_masagi_crop.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`main_menu home_menu ${isFixed ? 'menu_fixed animated fadeInDown' : ''}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to={{
                          pathname: "/",
                          search: "",
                          hash: "#home",
                          state: { fromDashboard: true }
                      }}> 
                  <img src={logo} alt="logo" /> 
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse main-menu-item justify-content-end" id="navbarSupportedContent">
                  <ul className="navbar-nav align-items-center">
                    <li className="nav-item active">
                      <Link className="nav-link" to={{
                          pathname: "/",
                          search: "",
                          hash: "#home",
                          state: { fromDashboard: true }
                      }}>Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={{
                          pathname: "/",
                          search: "",
                          hash: "#about",
                          state: { fromDashboard: true }
                      }}>Our Story</Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link dropdown-toggle" to={{
                          pathname: "/",
                          search: "",
                          hash: "#what_we_do",
                          state: { fromDashboard: true }
                      }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        What We Do
                      </Link>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to={{
                          pathname: "/",
                          search: "",
                          hash: "#what_we_do",
                          state: { fromDashboard: true }
                      }}>What We Do</Link>
                        <Link className="dropdown-item" to={{
                          pathname: "/",
                          search: "",
                          hash: "#why_us",
                          state: { fromDashboard: true }
                      }}>Why Us</Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={{
                          pathname: "/",
                          search: "",
                          hash: "#our_client",
                          state: { fromDashboard: true }
                      }}>Our Client</Link>
                    </li>
                    <li className="d-none d-lg-block">
                      <Link 
                        className="btn_start" 
                        style={{backgroundColor: '#f9b700'}}
                        to={{
                          pathname: "/contact/",
                          search: "",
                          hash: "#maps",
                          state: { fromDashboard: true }
                      }}>Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header> 
    </>
  )
};

export default Header;
