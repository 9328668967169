// components/WhyUs.js
import React from 'react';
import advanceImg from "../assets/img/advance_feature_img.png";

const WhyUs = () => {
  return (
    <section className="advance_feature learning_part" id="why_us" style={{ marginBottom: '120px', marginTop: '100px' }}>
      <div className="container">
        <div className="row align-items-sm-center align-items-xl-stretch">
          <div className="col-md-6 col-lg-6">
            <div className="learning_member_text">
              <h5>Why Us</h5>
              <h2>Deliver best-effective solution</h2>
              <p>
                Perusahaan teknologi informasi profesional yang berpengalaman di bidangnya, memberikan solusi efektif terbaik
                dan mewujudkan layanan kesinambungan di masa depan yang mendukung kesuksesan bisnis klien.
              </p>
            </div>
          </div>
            <div className="col-lg-6 col-md-6">
            <div className="learning_img">
              <img src={advanceImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
