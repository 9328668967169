// components/Banner.js
import React from 'react';

const Banner = () => {
  return (
    <section className="banner_part" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-xl-6">
            <div className="banner_text">
              <div className="banner_text_iner">
                <h5>Empowering technology</h5>
                <h1>Mitra Solusi Aktual Integrasi</h1>
                <p>
                  Masagi adalah perusahaan dengan solusi teknologi kelas dunia yang memberikan berbagai solusi inovatif
                  berdasarkan teknologi yang terintegrasi berdasarkan pada produk perangkat lunak dengan kinerja terbaik
                  bagi dunia usaha dalam memecahkan masalah lebih efektif dan efisien.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
