import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import sendEmail from "../services/email";

const GetInTouch = () => {
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const [subject, setSubject] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // sendEmail(name, email, subject, message);
            console.log(`send email: ${name} - ${email} - ${subject} - ${message} `);
        } catch (err) {
            setRefreshReCaptcha(!refreshReCaptcha);
            console.log(err);
        }
      };

    // useEffect(() => {
    //     console.log(subject);
    //     console.log(email);
    //     console.log(name);
    //     console.log(message);
    // }, [subject, email, name, message]);
    const setTokenFunc = (getToken) => {
        setToken(getToken);
      };


    return (
    <>
        <div className="col-12">
            <h2 className="contact-title">Get in Touch</h2>
        </div>
        <div className="col-lg-8">
            <form className="form-contact contact_form" method="post" id="contactForm" noValidate="novalidate" onSubmit={(e) => handleSubmit(e)} >
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTHA_SITE_KEY}>
                    <GoogleReCaptcha
                        className="google-recaptcha-custom-class"
                        onVerify={setTokenFunc}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                </GoogleReCaptchaProvider>
                <div className="row">
                    <div className="col-12">
                    <div className="form-group">
                        <textarea 
                            className="form-control w-100" 
                            cols="30" 
                            rows="9" 
                            onFocus={(e) => e.target.placeholder = ''} 
                            onBlur={(e) => e.target.placeholder = 'Enter Message'} 
                            placeholder="Enter Message"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        >
                        </textarea>
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                        <input 
                            className="form-control valid" 
                            type="text" 
                            onFocus={(e) => e.target.placeholder = ''} 
                            onBlur={(e) => e.target.placeholder = 'Enter your name'} 
                            placeholder="Enter your name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                        <input 
                            className="form-control valid"
                            type="email" 
                            onFocus={(e) => e.target.placeholder = ''} 
                            onBlur={(e) => e.target.placeholder = 'Enter email address'} 
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                    </div>
                    <div className="col-12">
                    <div className="form-group">
                        <input 
                            className="form-control" 
                            type="text" onFocus={(e) => e.target.placeholder = ''} 
                            onBlur={(e) => e.target.placeholder = 'Enter Subject'} 
                            placeholder="Enter Subject"
                            onChange={(e) => setSubject(e.target.value)}
                            value={subject}
                        />
                    </div>
                    </div>
                </div>
                <div className="form-group mt-3">
                    <button type="submit" className="button button-contactForm btn_1">Send Message</button>
                </div>
            </form>
        </div>
    </>
    )
}

export default GetInTouch;