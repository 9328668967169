import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import aipng from "../../assets/img/ai.png"
import RootLayout from "../../components/layout";

const AIService = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <RootLayout>
            <section className="breadcrumb breadcrumb_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>What We Do</h2>
                                    <p>Home<span>/</span>What We Do</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="blog_area single-post-area section_padding" id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img className="img-fluid" src={aipng} alt="" />
                                </div>
                                <div className="blog_details">
                                    <h2>Artificial intelligence</h2>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="far fa-user"></i> Technology information</a></li>
                                    </ul>
                                    <p>
                                        Teknologi AI (Artificial Intelligence) saat ini sudah dan akan terus memengaruhi berbagai kehidupan manusia di seluruh dunia.
                                        Apalagi, teknologi AI sendiri telah menjadi pendorong utama hadirnya teknologi-teknologi baru seperti big data, chatbot, mobil swakemudi, robotika, dan Internet of Things (IoT).
                                        Di perusahaan atau organisasi, adopsi teknologi ini diprediksi akan terus meningkat lantaran ragam manfaat yang bisa diberikan ke mereka, contohnya meningkatkan kepuasan pelanggan atau mengurangi risiko investasi yang buruk.
                                        Laporan IDC memperkirakan bahwa pengeluaran perusahaan untuk teknologi AI akan meningkat menjadi $97,9 miliar pada tahun 2023.
                                        Sedangkan survei terbaru PwC bertajuk “2021 AI Predictions Report” menemukan bahwa lebih dari 50% responden yang mengambil bagian di dalam survei tersebut mengatakan perusahaan mereka telah meningkatkan investasi di AI selama setahun terakhir.
                                        Lantas, apa itu AI? AI yang merupakan akronim dari Artificial Intelligence, terdiri dari dua suku kata. Artificial dapat diartikan sebagai “buatan”, sedangkan Intelligence diartikan sebagai “kecerdasan”. Maka, AI memiliki arti kecerdasan buatan.
                                        AI adalah sistem komputer (mesin) yang memiliki kecerdasan layaknya manusia. Dalam hal ini, AI mampu melakukan pembelajaran (perolehan informasi dan aturan untuk menggunakan informasi), penalaran (menggunakan aturan untuk mencapai kesimpulan), dan mengoreksi diri secara mandiri.
                                        Secara sederhana, AI merupakan sebuah sistem komputer yang bisa meniru cara berpikir manusia dalam menyelesaikan sebuah pekerjaan.
                                        Pada saat ini, contoh penerapan AI terbanyak yaitu pada produk atau layanan teknologi terbaru, penelitian, analisis perilaku konsumen bagi perusahaan, mendeteksi penipuan, proyeksi pasar atau perkiraan penjualan, memantau keamanan di internet dan IT, serta mengotomatisasi pekerjaan.
                                    </p>
                                    <p>
                                        1. Klasifikasi Utama dari AI: <br />
                                        <strong>Strong AI (AI Kuat)</strong>: <br />
                                            Sistem AI dengan kemampuan kognitif seperti manusia pada umumnya. Ketika diberikan tugas atau perintah yang belum dikenali, sistem AI ini memiliki cukup kecerdasan untuk menemukan solusi dari setiap tugas atau perintah yang dikerjakannya.<br />
                                        <strong>Strong AI (AI Kuat)Weak AI (AI Lemah)</strong>: <br />
                                            Sistem AI yang dirancang dan dilatih untuk tugas tertentu. Contoh: Apple Siri dan Google Assistant
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title">Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                <a href="#">project</a>
                                                </li>
                                                <li>
                                                <a href="#">programing</a>
                                                </li>
                                                <li>
                                                <a href="#">technology</a>
                                                </li>
                                                <li>
                                                <a href="#">ai</a>
                                                </li>
                                                <li>
                                                <a href="#">sistem</a>
                                                </li>
                                                <li>
                                                <a href="#">website</a>
                                                </li>
                                                <li>
                                                <a href="#">application</a>
                                                </li>
                                                <li>
                                                <a href="#">illustration</a>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title">Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder='Enter email' required />
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default AIService;