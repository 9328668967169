import React from 'react';
import LALogo from '../assets/img/clients/Logo-Lintasarta.png';
import TLKMLogo from '../assets/img/clients/Logo-Telkom.webp';

const OurClient = () => {
  return (
    <section className="member_counter" id="our_client">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5">
            <div className="section_tittle text-center">
              <p>WHO TRUSTS US</p>
              <h2>OUR CLIENT</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 offset-lg-3 col-sm-6 text-center">
            <div className="single_feature">
              <img src={LALogo} className="special_img our_client" alt="LINTASARTA" />
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 text-center">
            <div className="single_feature">
              <img src={TLKMLogo} className="special_img our_client" alt="TELKOM" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurClient;
