import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import integrationpng from "../../assets/img/icon/System Integration.png"
import RootLayout from "../../components/layout";

const IntegrationService = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <RootLayout>
            <section className="breadcrumb breadcrumb_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>What We Do</h2>
                                    <p>Home<span>/</span>What We Do</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="blog_area single-post-area section_padding" id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img className="img-fluid" src={integrationpng} alt="" />
                                </div>
                                <div className="blog_details">
                                    <h2>Sistem Integrasi (Middleware)</h2>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="far fa-user"></i> Technology information</a></li>
                                    </ul>
                                    <p>
                                        Integrasi sistem (system integration) adalah proses menggabungkan berbagai subsistem atau komponen perangkat lunak 
                                        dan perangkat keras yang terpisah ke dalam satu sistem kohesif yang berfungsi secara bersama-sama. 
                                        Tujuannya adalah untuk memastikan bahwa semua komponen bekerja bersama secara harmonis dan efisien untuk mencapai tujuan bisnis atau operasional yang diinginkan.
                                    </p>
                                    <p>
                                        - Manfaat Integration Sistem : <br />
                                        <br/>
                                        <strong>Peningkatan Efisiensi Operasional</strong>: <br />
                                        Integrasi sistem memungkinkan aliran informasi yang lebih cepat dan lebih lancar antara berbagai komponen, 
                                        mengurangi redundansi dan meningkatkan efisiensi operasional.<br />
                                        <strong>Penghematan Biaya</strong>: <br />
                                        Dengan mengintegrasikan sistem yang ada, perusahaan dapat mengurangi biaya yang terkait dengan pemeliharaan 
                                        sistem yang terpisah dan meminimalkan kebutuhan akan pelatihan tambahan. <br/>
                                        <strong>Pengambilan Keputusan yang Lebih Baik</strong>: <br />
                                        Akses real-time ke data yang terintegrasi dari berbagai sistem memungkinkan manajemen untuk membuat keputusan yang lebih cepat dan lebih tepat. <br/>
                                        <strong>Meningkatkan Produktivitas</strong>: <br />
                                        Karyawan dapat mengakses informasi dan alat yang mereka butuhkan dengan lebih cepat dan mudah, meningkatkan produktivitas mereka secara keseluruhan. <br/>
                                        <strong>Skalabilitas</strong>: <br />
                                        Sistem yang terintegrasi dapat dengan mudah ditingkatkan untuk memenuhi kebutuhan bisnis yang berkembang, tanpa perlu melakukan perubahan besar pada infrastruktur yang ada. <br/>
                                    </p>
                                    <p>
                                        Integrasi sistem adalah proses penting yang memungkinkan berbagai komponen teknologi bekerja bersama secara efektif. 
                                        Meskipun menghadapi berbagai tantangan, manfaat dari integrasi sistem, seperti peningkatan efisiensi operasional, penghematan biaya, dan pengambilan keputusan yang lebih baik, 
                                        menjadikannya investasi yang berharga bagi banyak organisasi. Dengan perencanaan yang tepat dan pemilihan metode integrasi yang sesuai, 
                                        perusahaan dapat mencapai tujuan bisnis mereka dengan lebih efektif dan efisien.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title">Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                <a href="#">project</a>
                                                </li>
                                                <li>
                                                <a href="#">programing</a>
                                                </li>
                                                <li>
                                                <a href="#">technology</a>
                                                </li>
                                                <li>
                                                <a href="#">internet</a>
                                                </li>
                                                <li>
                                                <a href="#">sistem</a>
                                                </li>
                                                <li>
                                                <a href="#">IoT</a>
                                                </li>
                                                <li>
                                                <a href="#">application</a>
                                                </li>
                                                <li>
                                                <a href="#">sensor</a>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title">Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder='Enter email' required />
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default IntegrationService;