// App.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RootLayout from '../../components/layout';
import Banner from '../../components/banner';
import About from '../../components/about';
import WhyUs from '../../components/whyUs';
import WhatWeDo from '../../components/whatWeDo';
import OurClient from '../../components/ourClient';

function Home() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <RootLayout>
      <Banner />
      <About />
      <WhatWeDo />
      <WhyUs />
      <OurClient />
    </RootLayout>
  );
}

export default Home;
