import RootLayout from "../../components/layout";
import storyImg from "../../assets/img/our_story_original.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const About = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        }
    }, [hash]);

    return (
        <RootLayout>
            <section className="breadcrumb breadcrumb_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>Our Story</h2>
                                    <p>Home<span>/</span>Our Story</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="course_details_area section_padding" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 course_details_left">
                            <div className="main_image">
                                <img className="img-fluid" src={storyImg} alt="" />
                            </div>
                            <div className="content_wrapper">
                                <h4 className="title_top">OUR STORY</h4>
                                <div className="content">
                                    Masagi terbentuk berawal dari sebuah harapan yang dapat memudahkan para pelaku bisnis terintegrasi 
                                    satu sama lain dengan solusi teknologi informasi dan sistem informasi. Masagi hadir untuk menjadi perusahaan 
                                    konsultan teknologi informasi yang memiliki kompetensi nasional dan internasional, berorientasi pada bisnis 
                                    komersial dan pemerintahan, serta bertekad untuk menciptakan produk dan layanan yang mampu bersaing dan mengambil 
                                    bagian dalam perkembangan teknologi informasi local dan internasional. Masagi fokus pada bidang usaha rekayasa perangkat lunak, 
                                    pengembangan teknologi blockchain, pengembangan berbasis kecerdasan buatan, keamanan informasi, internet of thing (IoT), 
                                    integrasi sistem dan pelatihan kerja teknologi informasi. Beralatar belakang pendidikan dan berbekal pengalaman yang berbeda 
                                    dengan wawasan pengetahuan yang luas serta komitmen dan integritas dari para founder, Masagi percaya bahwa solusi teknologi informasi 
                                    yang Masagi berikan akan menjadi solusi yang terbaik dan berbeda dari solusi yang ditawarkan penyedia solusi lainnya.
                                    <br/>

                                    <h4 className="title">VISI</h4>
                                    <div className="content">
                                        Menjadi perusahaan konsultan teknologi informasi yang berkompetensi dan diakui secara internasional
                                        <br/>
                                    </div>
                                    <h4 className="title">MISI</h4>
                                    <div className="content">
                                            Mengedepankan profesionalisme, kemampuan individu, dan tim dalam meng hasilkan produk-produk unggulan.
                                            Memberikan solusi yang optimal kepada mitra kerja dalam mengatasi berbagai permasalahan di dunia teknologi informasi.
                                        <br/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default About;