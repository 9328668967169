import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import blockchainpng from "../../assets/img/icon/Blockchain Technology Development 2.png"
import RootLayout from "../../components/layout";

const BlockchainService = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <RootLayout>
            <section className="breadcrumb breadcrumb_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>What We Do</h2>
                                    <p>Home<span>/</span>What We Do</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="blog_area single-post-area section_padding" id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img className="img-fluid" src={blockchainpng} alt="" />
                                </div>
                                <div className="blog_details">
                                    <h2>Blockchain</h2>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="far fa-user"></i> Technology information</a></li>
                                    </ul>
                                    <p>
                                        Blockchain adalah sebuah teknologi yang memungkinkan penyimpanan data digital secara terdesentralisasi dan aman. 
                                        Blockchain pertama kali diperkenalkan sebagai teknologi dasar di balik cryptocurrency seperti Bitcoin, 
                                        tetapi sekarang penggunaannya telah meluas ke berbagai sektor lainnya.
                                    </p>
                                    <p>
                                        - Cara Kerja Blockchain : <br />
                                        Blockchain bekerja dengan cara mencatat transaksi dalam "blok" data. 
                                        Setiap blok berisi sejumlah transaksi, dan setelah blok penuh, blok tersebut ditambahkan ke "rantai" blok yang ada sebelumnya. 
                                        Setiap blok memiliki referensi kriptografis ke blok sebelumnya, membentuk rantai yang terus bertambah. 
                                        Berikut adalah elemen-elemen kunci dalam cara kerja blockchain: <br/>
                                        <strong>Desentralisasi</strong>: <br />
                                        Tidak ada pusat kendali tunggal dalam jaringan blockchain. Semua peserta dalam jaringan memiliki salinan buku besar (ledger) yang sama dan diperbarui secara bersamaan.<br />
                                        <strong>Transparansi</strong>: <br />
                                        Setiap transaksi yang dicatat dalam blockchain dapat dilihat oleh semua peserta dalam jaringan. 
                                        Ini menciptakan transparansi penuh karena semua peserta dapat memverifikasi transaksi. <br/>
                                        <strong>Keamanan</strong>: <br />
                                        Setiap blok dalam blockchain dienkripsi dan diikat dengan blok sebelumnya menggunakan hash kriptografis. 
                                        Ini membuatnya sangat sulit untuk mengubah informasi dalam satu blok tanpa mengubah semua blok sebelumnya, 
                                        yang memerlukan konsensus dari mayoritas peserta jaringan. <br/>
                                        <strong>Konsensus</strong>: <br />
                                        Untuk menambahkan blok baru ke rantai, mayoritas peserta dalam jaringan harus mencapai kesepakatan (konsensus). 
                                        Algoritma konsensus seperti Proof of Work (PoW) dan Proof of Stake (PoS) 
                                        digunakan untuk memastikan keabsahan transaksi dan menambahkan blok baru ke blockchain. <br/>
                                    </p>
                                    <p>
                                        Blockchain adalah teknologi revolusioner yang menawarkan solusi untuk banyak masalah dalam berbagai industri melalui desentralisasi, transparansi, dan keamanan. 
                                        Meskipun masih menghadapi beberapa tantangan, potensi aplikasi blockchain terus berkembang, menjadikannya salah satu inovasi teknologi paling menarik di era digital ini.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="comment-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title">Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                <a href="#">project</a>
                                                </li>
                                                <li>
                                                <a href="#">programing</a>
                                                </li>
                                                <li>
                                                <a href="#">technology</a>
                                                </li>
                                                <li>
                                                <a href="#">blockchain</a>
                                                </li>
                                                <li>
                                                <a href="#">sistem</a>
                                                </li>
                                                <li>
                                                <a href="#">security</a>
                                                </li>
                                                <li>
                                                <a href="#">application</a>
                                                </li>
                                            </ul>
                                        </aside>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title">Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder='Enter email' required />
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default BlockchainService;